import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {CoreDataSource} from '../core.datasource';
import {Observable} from 'rxjs';
import {GlobalStateService} from '../../global-state.service';

@Injectable()
export class CsrfInterceptor implements HttpInterceptor {

    constructor(private dataSource: CoreDataSource, private state: GlobalStateService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!this.state.getCSRFToken()) {
            return next.handle(request);
        }

        const clonedRequest = request.clone({
            headers: request.headers.set('X-CSRF-Token', this.state.getCSRFToken())
        });

        return next.handle(clonedRequest);
    }
}
