import {Directive, OnInit} from '@angular/core';
import {DataBindingDirective, GridComponent, GridDataResult} from '@progress/kendo-angular-grid';
import {catchError} from 'rxjs/operators';
import {of} from 'rxjs';
import {SaleService} from '../../service/sale.service';
import {State} from '@progress/kendo-data-query';

@Directive({
    selector: '[appDelonghiNetworkSalesBinding]'
})
export class SalesTableDirective extends DataBindingDirective implements OnInit {

    public state: State = {};
    constructor(
        private dataProvider: SaleService,
        public grid: GridComponent
    ) {
        super(grid);
    }

    public ngOnInit(): void {
        super.ngOnInit();
        this.rebind();
    }

    public rebind(): void {

        this.grid.loading = true;
        /**
         * TODO: remake default filters to right way.
         * @see https://www.telerik.com/kendo-angular-ui/components/grid/filtering/
         */
        if (!this.state.filter) {
            this.state.filter = {
                logic: 'and',
                filters: []
            };
        }

        this.dataProvider
            .find(this.state)
            .pipe(
                catchError(() => of([])),
            )
            .subscribe((response) => {
                this.grid.loading = false;  // hide the loading indicator
                this.grid.data = (<GridDataResult>{
                    data: response['data'],
                    total: parseInt(response['total'], 10)
                });
                this.notifyDataChange();
            });
    }

    public loadAsCsv() {
        if (!this.state.filter) {
            this.state.filter = {
                logic: 'and',
                filters: []
            };
        }

        return this.dataProvider.loadAsCsv(this.state);
    }


}
