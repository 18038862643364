import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {Location} from '@angular/common';


@Component({
    selector: 'app-delonghi-network-tabs',
    templateUrl: './main-layout.component.html',
    styleUrls: ['./main-layout.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class MainLayoutComponent {

    public selectedTab = 'sales';

    protected tabs = [
        'sellers',
        'promotional-users',
        'actual-sales',
        'sales',
        'promotional-products'
    ];

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private  location: Location
    ) {
        this.route.paramMap.subscribe(
            (params: ParamMap) => {
                const tabId = params.has('tabId')
                    ? params.get('tabId')
                    : 'actual-sales';

                if (!this.tabs.includes(tabId)) {
                    this.router.navigate(['/not-found'], { skipLocationChange: true });
                    return;
                }
                this.selectedTab = tabId;
            }
        );
    }

    onTabSelect(event) {
        if (!this.tabs[event.index]) {
            this.router.navigate(['/not-found'], { skipLocationChange: true });
            return ;
        }

        this.location.go('delonghi-network/' + this.tabs[event.index]);
    }
}
