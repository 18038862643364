import {NgModule} from '@angular/core';
import {AlertModule, BsDropdownModule, CollapseModule, ModalModule, TooltipModule, TypeaheadModule} from 'ngx-bootstrap';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';


@NgModule({
    imports: [
        BsDropdownModule.forRoot(),
        TooltipModule.forRoot(),
        ModalModule.forRoot(),
        TypeaheadModule.forRoot(),
        AlertModule.forRoot(),
        CollapseModule.forRoot(),
        FontAwesomeModule
    ],
    exports: [
        BsDropdownModule,
        TooltipModule,
        ModalModule,
        TypeaheadModule,
        AlertModule,
        CollapseModule,
        FontAwesomeModule
    ]
})

export class UiModule {

}
