import {Component} from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '../../../../environments/environment';
import {GlobalStateService} from '../../../global-state.service';
import {CoreDataSource} from '../../core.datasource';
import {NgxSpinnerService} from 'ngx-spinner';
import {faBell, faCog, faUserCircle} from '@fortawesome/free-solid-svg-icons';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
    isUserLoggedIn: boolean = this.authService.isUserLoggedIn.getValue();
    mainLinks;
    remindersLink;
    isHidden = false;
    isCollapsed = true;
    baseUrl = environment.drupalUrl;
    currentLang;
    faCog = faCog;
    faBell = faBell;
    faUser = faUserCircle;

    // TODO: move mapping and menu loader in separate service
    protected pathToRouteMapping = {
        'tools/rsm-schedule' : 'rsm-schedule',
        'tools/delonghi_network/sales' : 'delonghi-network/sales',
        'tools/delonghi_network/promotional-users': 'delonghi-network/promotional-users',
        'tools/delonghi_network/promotional-products': 'delonghi-network/promotional-products',
        'tools/delonghi_network/actual-sales': 'delonghi-network/actual-sales',
        'tools/delonghi_network/sellers': 'delonghi-network/sellers',
        'tools/start-pages': 'start-pages',
        'to-start-system': '/',
        'infospace': 'infospace'
    };
    user;


    constructor(private authService: AuthService,
                private router: Router,
                public translate: TranslateService,
                private coreDataSource: CoreDataSource,
                private globalState: GlobalStateService,
                private spinner: NgxSpinnerService,
                private sanitizer: DomSanitizer) {
        this.currentLang = this.globalState.language;

        this.authService.isUserLoggedIn.subscribe(value => {
            this.isUserLoggedIn = value;
            if (value) {
                const menuLinks = this.globalState.getLinks();
                this.mainLinks = menuLinks.main;
                this.managementLinks = menuLinks.management;
                this.remindersLink = menuLinks.reminders;
                this.user = this.globalState.user;
                this.sanitizer.bypassSecurityTrustResourceUrl(this.user['avatar-url']);
            }
        });
        this.globalState.hideHeader.asObservable().subscribe(response => this.isHidden = response);
    }

    private _managementLinks;

    get managementLinks() {
        return this._managementLinks;
    }

    set managementLinks(value) {
        if (value.length > 0) {
            this._managementLinks = value[0].below;
        } else {
            this._managementLinks = [];
        }
    }

    logout() {
        this.authService.logout();
        this.router.navigate(['/login']);
    }

    setEnLocale() {
        this.setLocale('en');
    }

    setRuLocale() {
        this.setLocale('ru');
    }

    private setLocale(locale) {
        if (locale === this.globalState.language) {
            return;
        }
        this.spinner.show();
        this.translate.use(locale);
        this.globalState.language = locale;
        this.currentLang = locale;
        this.coreDataSource.getHeaderLinks(locale).subscribe(response => {
            this.mainLinks = response.data.main;
            this.managementLinks = response.data.management;
            this.spinner.hide();
        });
    }

    public isPathMappedToInternalRoute(path: string): boolean {
        if (typeof path !== 'string' || path === '') {
            return false;
        }

        return this.pathToRouteMapping.hasOwnProperty(path);
    }

    public getInternalRouteForPath(path: string): string {
        return this.pathToRouteMapping[path];
    }
}
