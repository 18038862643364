import {Component, ElementRef, Input, TemplateRef, ViewChild} from '@angular/core';
import {NgxSpinnerService} from 'ngx-spinner';
import {faInfoCircle, faUpload} from '@fortawesome/free-solid-svg-icons';
import {HttpClient} from '@angular/common/http';
import {FileUploadingResult} from './model/FileUploadingResult';
import {DialogService} from '@progress/kendo-angular-dialog';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-file-uploader',
    templateUrl: './file-uploader.component.html',
    styleUrls: ['./file-uploader.component.scss']
})
export class FileUploaderComponent {
    // TODO: implement as @Input
    public faUpload = faUpload;
    public faInfo = faInfoCircle;
    @Input() url: string;
    @ViewChild('file', { static: false }) fileForUpload: ElementRef;

    constructor(
        private http: HttpClient,
        private spinner: NgxSpinnerService,
        private dialogService: DialogService,
        private translate: TranslateService
    ) {
    }

    protected _uploadingResult: FileUploadingResult = null;

    get uploadingResult() {
        return this._uploadingResult;
    }

    protected _loading: boolean = false;

    get loading() {
        return this._loading;
    }

    private _loadingError: boolean = false;

    get loadingError(): boolean {
        return this._loadingError;
    }

    public openUploadDialog() {
        this.fileForUpload.nativeElement.click();
    }

    public async startUploadActiveSalesData(files: FileList) {
        this._loading = true;
        this._loadingError = false;

        await this.spinner.show('fileLoaderSpinner');
        this.postFile(files.item(0))
            .subscribe(
                async (res: FileUploadingResult) => {
                    this._uploadingResult = res;
                    this._loading = false;
                    await this.spinner.hide('fileLoaderSpinner');
                },
                async (error) => {
                    this._uploadingResult = null;
                    this._loadingError = true;
                    this._loading = false;
                    await this.spinner.hide('fileLoaderSpinner');
                }
            );
    }

    public async openResultDialog(template: TemplateRef<any>) {
        const title = await this.translate.get('fileUploader.fileUploadingResult').toPromise();
        this.dialogService.open({
            title: title,
            content: template,
            width: 500,
            actions: [
                {text: 'Ok', primary: true}
            ]
        });
    }

    public calculateInfoIconColor() {
        return (this.uploadingResult && Number(this.uploadingResult.countErrors) > 0) || this._loadingError
            ? 'red'
            : 'green';
    }

    protected postFile(fileToUpload: File) {
        const formData: FormData = new FormData();
        formData.append('file', fileToUpload);

        return this.http
            .post(
                this.url,
                formData
            );
    }
}
