import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginComponent} from './core/pages/login/login.component';
import {AuthGuard} from './core/guards/auth.guard';
import {NotFoundComponent} from './core/components/not-found.component';

const routes: Routes = [
    {path: 'login', component: LoginComponent},
    {
        path: '',
        loadChildren: () => import('./modules/reporting-module/reporting.module').then(m => m.ReportingModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'rsm-schedule',
        loadChildren: () => import('./modules/rsm-schedule/schedule.module').then(m => m.ScheduleModule),
        canActivate: [AuthGuard]
    },
    // {path: '', component: HomeComponent, canActivate: [AuthGuard]},
    {
        path: 'delonghi-network',
        loadChildren: () => import('./modules/delonghi-network/delonghi-network.module').then(m => m.DelonghiNetworkModule)
    },
    {
        path: 'infospace',
        loadChildren: () => import('./modules/info-space/info-space.module').then(m => m.InfoSpaceModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'dictionaries',
        loadChildren: () => import('./modules/dictionaries/dictionaries.module').then(m => m.DictionariesModule),
        canActivate: [AuthGuard]
    },
    {path: 'not-found', component: NotFoundComponent, canActivate: [AuthGuard]},
    {path: '**', component: NotFoundComponent, canActivate: [AuthGuard]}
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [
        RouterModule
    ]
})

export class AppRoutingModule {
}
