import {Component} from '@angular/core';

@Component({
    selector: 'app-not-found',
    template: `
        <h2>
            404 - {{'global.pageNotFound' | translate}}
        </h2>
    `
})
export class NotFoundComponent {
}
