import {Injectable} from '@angular/core';
import {CoreDataSource} from '../core.datasource';
import {Subject} from 'rxjs';

@Injectable()
export class HeaderService {

    constructor(private coreDataSource: CoreDataSource) {
    }

    links: Subject<Object> = new Subject();

    init() {
        this.coreDataSource.getHeaderLinks().subscribe(response => {
            this.links.next(response.data);
        });
        return this.links.asObservable();
    }
}
