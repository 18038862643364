import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {NgxPermissionsGuard} from 'ngx-permissions';
import {MainLayoutComponent} from './components/main-layout/main-layout.component';

const routes: Routes = [
    {
        path: ':tabId',
        component: MainLayoutComponent,
        canActivate: [NgxPermissionsGuard],
        data: {
            permissions: {
                only: [
                    'view delonghi_network self_registered_users1',
                    'view delonghi_network promotional_users',
                    'view delonghi_network promotional_products',
                    'view delonghi_network actual_sales',
                    'view delonghi_network sales',
                ]
            }
        }
    },
    {
        path: '',
        component: MainLayoutComponent,
        canActivate: [NgxPermissionsGuard],
        data: {
            permissions: {
                only: [
                    'view delonghi_network self_registered_users1',
                    'view delonghi_network promotional_users',
                    'view delonghi_network promotional_products',
                    'view delonghi_network actual_sales',
                    'view delonghi_network sales',
                ]
            }
        }
    }
];


@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class DelonghiNetworkRoutingModule {
}
