<div class="main-delonghi-network">
    <div class="breadcrumbs">
        <div class="breadcrumb-item">
            <strong>Delonghi network</strong>
        </div>
    </div>
    <div class="tabs-container delonghi-network">
        <kendo-tabstrip (tabSelect)="onTabSelect($event)" keepTabContent="true">
            <kendo-tabstrip-tab
                *ngxPermissionsOnly="'view delonghi_network self_registered_users'"
                [selected]="'sellers' === selectedTab"
                title="{{ 'delonghiNetwork.registeredSellers' | translate}}"
            >
                <ng-template kendoTabContent>
                    <app-sellers-table></app-sellers-table>
                </ng-template>
            </kendo-tabstrip-tab>
            <kendo-tabstrip-tab
                *ngxPermissionsOnly="'view delonghi_network promotional_users'"
                [selected]="'promotional-users' === selectedTab"
                title="{{ 'delonghiNetwork.promotionalUsers' | translate}}"
            >
                <ng-template kendoTabContent>
                    <app-promotional-users-table></app-promotional-users-table>
                </ng-template>
            </kendo-tabstrip-tab>
            <kendo-tabstrip-tab
                *ngxPermissionsOnly="'view delonghi_network actual_sales'"
                [selected]="'actual-sales' === selectedTab"
                title="{{ 'delonghiNetwork.actualSales' | translate}}"
            >
                <ng-template kendoTabContent>
                    <app-actual-sales-table></app-actual-sales-table>
                </ng-template>
            </kendo-tabstrip-tab>
            <kendo-tabstrip-tab
                *ngxPermissionsOnly="'view delonghi_network sales'"
                [selected]="'sales' === selectedTab"
                title="{{ 'delonghiNetwork.sales' | translate}}"
            >
                <ng-template kendoTabContent>
                    <app-sales-table></app-sales-table>
                </ng-template>
            </kendo-tabstrip-tab>
            <kendo-tabstrip-tab
                *ngxPermissionsOnly="'view delonghi_network promotional_products'"
                [selected]="'promotional-products' === selectedTab"
                title="{{ 'delonghiNetwork.promotionalProducts' | translate}}"
            >
                <ng-template kendoTabContent>
                    <app-promotional-products-table></app-promotional-products-table>
                </ng-template>
            </kendo-tabstrip-tab>
        </kendo-tabstrip>
    </div>
    <div kendoDialogContainer></div>
</div>
