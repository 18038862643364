<div class="form-signin">
    <alert type="danger" *ngIf=!!errorMsg>{{errorMsg}}</alert>
    <form [formGroup]="loginForm" (ngSubmit)="login()">
        <div class="form-group">
            <input placeholder="Login" name="username" class="form-control" formControlName="username">
        </div>
        <div class="form-group">
            <input placeholder="Password" type='password' name="password" class="form-control"
                   formControlName="password">
        </div>
        <button type="submit" class="btn btn-primary float-right">Submit</button>
    </form>
</div>
<app-footer></app-footer>
