import {VERSION} from '../../../environments/version';
import {environment} from '../../../environments/environment';
import {Component} from '@angular/core';

@Component({
    selector: 'app-footer',
    template: `
        <footer> Version: {{version.version}} <span *ngIf="dev">Build: {{version.hash}}</span></footer>
    `,
    styles: ['app-footer:host { position:absolute; bottom: 0; right: 15px}']
})

export class FooterComponent {
    version = VERSION;
    dev = !environment.production;
}
