import {Component, ViewChild} from '@angular/core';
import {SortDescriptor, State} from '@progress/kendo-data-query';
import {environment} from '../../../../../environments/environment';
import {SalesTableDirective} from '../sales-table/sales-table.directive';

@Component({
    selector: 'app-promotional-users-table',
    templateUrl: './promotional-users-table.component.html',
    styleUrls: ['./promotional-users-table.component.scss']
})
export class PromotionalUsersTableComponent {

    public apiBaseUrl = environment.api;

    public state: State = {
        filter: {
            logic: 'and',
            filters: []
        }
    };

    public sort: SortDescriptor[] = [{
        field: 'fio',
        dir: 'asc'
    }];

    constructor() {
    }
}
