<div *ngIf="!isHidden && isUserLoggedIn" class="container-fluid">
    <nav class="navbar navbar-expand-lg navbar-dark bg-dark fixed-top">
        <a class="navbar-brand" routerLink="/"><strong>start</strong>.delonghi</a>
        <button (click)="isCollapsed = !isCollapsed" aria-expanded="false" class="navbar-toggler" data-toggle="collapse"
                type="button">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div [collapse]=isCollapsed class="collapse navbar-collapse">
            <ul *ngFor="let link of mainLinks" class="navbar-nav">
                <li *ngIf="link.below" class="dropdown nav-item" dropdown>
                    <a class="nav-link" dropdownToggle href="javascript:void(0);">{{link.title}}</a>
                    <ul *dropdownMenu class="dropdown-menu" role="menu">
                        <ng-container *ngFor="let subLink of link.below">
                            <li *ngIf="subLink.path" role="menuitem" class="nav-item">
                                <a class="dropdown-item"
                                   *ngIf="!isPathMappedToInternalRoute(subLink.path) else linkToInternalRoute"
                                   href="{{baseUrl +'/'+ subLink.path}}"
                                   target="_blank"
                                >
                                    {{subLink.title}}</a>
                                <ng-template #linkToInternalRoute>
                                    <a class="dropdown-item"
                                       [routerLink]="getInternalRouteForPath(subLink.path)">{{subLink.title}}</a>
                                </ng-template>
                            </li>
                        </ng-container>
                        <li role="menuitem" class="nav-item">
                            <a class="dropdown-item" *ngIf="link.path === 'tools'"
                               [routerLink]="['/dictionaries/staff']">{{'employees' | translate}}</a>
                        </li>
                    </ul>
                </li>
                <li *ngIf="!link.below && link.path !== 'to-start-system'">
                    <a class="nav-link"
                       *ngIf="!isPathMappedToInternalRoute(link.path) else linkToInternalRoute"
                       href="{{baseUrl +'/'+ link.path}}"
                       target="_blank"
                    >
                        {{link.title}}</a>
                    <ng-template #linkToInternalRoute>
                        <a class="nav-link" [routerLink]="getInternalRouteForPath(link.path)">{{link.title}}</a>
                    </ng-template>
                </li>
            </ul>
            <ul class="navbar-nav ml-auto">
                <li class="nav-item lang">
                    <div (click)="setRuLocale()" [ngClass]="{'lang__item_active': 'ru' === currentLang}"
                         class="lang__item lang__item_first nav-link">RU
                    </div>
                    <div (click)="setEnLocale()" [ngClass]="{'lang__item_active': 'en' === currentLang}"
                         class="lang__item nav-link">EN
                    </div>
                </li>

                <li *ngIf="remindersLink.access" class="nav-item">
                    <a class="nav-link reminders" href="{{baseUrl +'/'+ remindersLink.path}}" target="_blank">
                        <fa-icon [icon]="faBell" size="2x"></fa-icon>
                        <span class="reminders__number">{{remindersLink.notificationNumber}}</span>
                    </a>
                </li>

                <li *ngIf="managementLinks.length" class="nav-item dropdown" dropdown>
                    <a class="nav-link" dropdownToggle href="javascript:void(0);">
                        <fa-icon [icon]="faCog" size="2x"></fa-icon>
                    </a>
                    <ul *dropdownMenu class="dropdown-menu dropdown-menu__management" role="menu">
                        <li *ngFor="let managementLink of managementLinks" role="menuitem">
                            <a *ngIf="managementLink.path" class="dropdown-item"
                               href="{{baseUrl +'/'+ managementLink.path}}" target="_blank">
                                {{managementLink.title}}</a>
                        </li>
                    </ul>
                </li>

                <li class="nav-item dropdown" dropdown>
                    <a class="nav-link" dropdownToggle href="javascript:void(0);">
                        <fa-icon *ngIf="!user['avatar-url']" [icon]="faUser" size="2x"></fa-icon>
                        <div *ngIf="!!user['avatar-url']"
                             [ngStyle]="{'background-image': 'url(' + user['avatar-url'] + ')'}"
                             class="user-avatar"></div>
                    </a>
                    <ul *dropdownMenu class="dropdown-menu dropdown-menu__user-menu" role="menu">
                        <li role="menuitem">
                            <a *ngIf="user" class="dropdown-item" href="{{baseUrl +'/user/'+ user.id + '/edit'}}"
                               target="_blank">
                                <div [title]="user.name.length > 7 ? user.name : ''"
                                     class="dropdown-item__user-name">{{user.name}}
                                </div>
                            </a>
                        </li>
                        <li role="menuitem">
                            <a (click)="logout()" class="dropdown-item" href="javascript:void(0);" translate>
                                Logout</a>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    </nav>
</div>
