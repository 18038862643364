import {Directive, Input, OnInit} from '@angular/core';
import {DataBindingDirective, GridComponent, GridDataResult} from '@progress/kendo-angular-grid';
import {catchError} from 'rxjs/operators';
import {of} from 'rxjs';
import {State} from '@progress/kendo-data-query';
import {HttpClient} from '@angular/common/http';

@Directive({
    selector: '[appKendoGridDataProvider]'
})
export class KendoGridDataProviderDirective extends DataBindingDirective implements OnInit {

    @Input() url: string;

    public state: State = {};

    constructor(
        public grid: GridComponent,
        private http: HttpClient
    ) {
        super(grid);
    }

    public ngOnInit(): void {
        super.ngOnInit();
        this.rebind();
    }

    public rebind(): void {
        this.grid.loading = true;
        /**
         * TODO: remake default filters to right way.
         * @see https://www.telerik.com/kendo-angular-ui/components/grid/filtering/
         */
        if (!this.state.filter) {
            this.state.filter = {
                logic: 'and',
                filters: []
            };
        }

        this.http.get<any>(
            this.url,
            {params: {criteria: JSON.stringify(this.state)}}
        )
            .pipe(
                catchError(() => of([])),
            )
            .subscribe((response) => {
                this.grid.loading = false;  // hide the loading indicator
                this.grid.data = (<GridDataResult>{
                    data: response['data'],
                    total: parseInt(response['total'], 10)
                });
                this.notifyDataChange();
            });
    }
}
