import {Component} from '@angular/core';
import {SortDescriptor, State} from '@progress/kendo-data-query';
import {environment} from '../../../../../environments/environment';

@Component({
    selector: 'app-sellers-table',
    templateUrl: './sellers-table.component.html'
})
export class SellersTableComponent {

    public apiBaseUrl = environment.api;

    public state: State = {
        filter: {
            logic: 'and',
            filters: []
        }
    };

    public sort: SortDescriptor[] = [{
        field: 'name',
        dir: 'asc'
    }];

    constructor() {
    }

}
