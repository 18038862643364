<ng-template #popTemplate>
    <div *ngIf="uploadingResult" class="fileProcessingResult">
        <div>
            <label>{{'fileUploader.countCreatedEntities' | translate }}:</label>
            <span> {{uploadingResult.countCreated}} </span>
        </div>
        <div>
            <label>{{'fileUploader.countUpdatedEntities' | translate }}:</label>
            <span> {{uploadingResult.countUpdated}} </span>
        </div>
        <div>
            <label>{{'fileUploader.countErrors' | translate }}:</label>
            <span> {{uploadingResult.countErrors}} </span>
        </div>
    </div>
    <div *ngIf="loadingError" class="error">
        <div>{{'fileUploader.criticalError' | translate }}</div>
    </div>
</ng-template>

<div class="app-file-loader-wrapper">
    <div class="ui-wrapper">
        <div class="spinner-container">
            <ngx-spinner
                bdOpacity=0
                bdColor="rgba(0,0,0,0)"
                name="fileLoaderSpinner"
                size="default"
                color="#fff"
                type="line-scale"
                [fullScreen]="false"
            >
            </ngx-spinner>
        </div>
        <div *ngIf="!loading" class="uiElements-container">
            <input #file style="visibility: hidden" type="file" name="file"
                   (change)="startUploadActiveSalesData($event.target.files)">
            <fa-icon (click)="openUploadDialog()" [icon]="faUpload" accept=".csv" [size]="'2x'"></fa-icon>
            <fa-icon
                *ngIf="uploadingResult || loadingError"
                (click)="openResultDialog(popTemplate)"
                [icon]="faInfo"
                [style.color]="calculateInfoIconColor()"
                accept=".csv"
                [size]="'1x'"
            ></fa-icon>
        </div>
    </div>
</div>
