import {Component, ViewChild} from '@angular/core';
import {SortDescriptor, State} from '@progress/kendo-data-query';
import {SalesTableDirective} from './sales-table.directive';
import {faFileCsv} from '@fortawesome/free-solid-svg-icons';


@Component({
    selector: 'app-sales-table',
    templateUrl: './sales-table.component.html',
    styleUrls: ['./sales-table.component.scss']
})
export class SalesTableComponent {

    public state: State = {
        filter: {
            logic: 'and',
            filters: []
        }
    };
    public sort: SortDescriptor[] = [{
        field: 'receivingDateTime',
        dir: 'desc'
    }];
    public faFileCsv = faFileCsv;
    @ViewChild(SalesTableDirective, { static: true }) dataProvider: SalesTableDirective;


    constructor() {
    }

    loadAsCsv() {
        this.dataProvider
            .loadAsCsv()
            .subscribe((newBlob) => {

                const now = new Date();
                const dateAsStr = `${now.getFullYear()}-${('0' + (now.getMonth() + 1)).slice(-2)}-${('0' + now.getDate()).slice(-2)}`;
                const timeAsStr = `${now.getHours()}-${now.getMinutes()}-${now.getSeconds()}`;
                const filename = `sales-${dateAsStr}T${timeAsStr}.csv`;

                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(newBlob, filename);
                    return;
                }

                const blob = window.URL.createObjectURL(newBlob);

                const link = document.createElement('a');
                link.href = blob;
                link.setAttribute('target', '_blank');
                link.download = filename;
                // this is necessary as link.click() does not work on the latest firefox
                link.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}));

                // For Firefox it is necessary to delay revoking the ObjectURL
                setTimeout(() => window.URL.revokeObjectURL(blob), 100);
            });
    }
}
