import {Injectable} from '@angular/core';
import {AuthService} from '../services/auth.service';
import {CoreDataSource} from '../core.datasource';

@Injectable()
export class UserRepository {
    constructor(private authService: AuthService,
                private coreDataSource: CoreDataSource) {
    }

    getUser(id: number) {
        return this.coreDataSource.getUser(id);
    }

    getCurrentUser() {
        return this.coreDataSource.getCurrentUser();
    }
}
