<div class="row col-12 head-row flex-row justify-content-between">
    <h5>{{ 'delonghiNetwork.promotionalUsers' | translate}}</h5>
    <app-file-uploader
        url = "{{apiBaseUrl}}/api/delonghi-network/promotional-users"
    ></app-file-uploader>
</div>
<div class="table-container">
    <kendo-grid
        appKendoGridDataProvider
        url="{{apiBaseUrl}}/api/delonghi-network/promotional-users"
        [pageSize]="20"
        [pageable]="true"
        [sort]="sort"
        [sortable]="true"
        [filter]="state.filter"
        filterable="menu"
        class="scrollbar"
    >
        <kendo-grid-column field="fio" title="{{'delonghiNetwork.fio' | translate }}">
            <ng-template  kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
                <kendo-grid-string-filter-menu [column]="column" [filter]="filter" [filterService]="filterService" [extra]=false>
                    <kendo-filter-contains-operator></kendo-filter-contains-operator>
                </kendo-grid-string-filter-menu>
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="phone" title="{{'delonghiNetwork.phone' | translate }}">
            <ng-template  kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
                <kendo-grid-string-filter-menu [column]="column" [filter]="filter" [filterService]="filterService" [extra]=false>
                    <kendo-filter-contains-operator></kendo-filter-contains-operator>
                </kendo-grid-string-filter-menu>
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="email" title="{{'delonghiNetwork.email' | translate }}">
            <ng-template  kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
                <kendo-grid-string-filter-menu [column]="column" [filter]="filter" [filterService]="filterService" [extra]=false>
                    <kendo-filter-contains-operator></kendo-filter-contains-operator>
                </kendo-grid-string-filter-menu>
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="active" title="{{'delonghiNetwork.active' | translate }}" [filterable]="false">
            <ng-template kendoGridCellTemplate let-dataItem>
                {{ (dataItem.active ? 'global.yes' : 'global.no') | translate }}
            </ng-template>
        </kendo-grid-column>

        <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">

            <div class="app-page-wrapper">
                <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
                <kendo-pager-numeric-buttons [buttonCount]="10"></kendo-pager-numeric-buttons>
                <kendo-pager-next-buttons></kendo-pager-next-buttons>
                <kendo-pager-info></kendo-pager-info>
            </div>
        </ng-template>
    </kendo-grid>
</div>

