import { Routes } from '@angular/router';
import { NgxPermissionsGuard } from 'ngx-permissions';
import { MainLayoutComponent } from './components/main-layout/main-layout.component';
const ɵ0 = {
    permissions: {
        only: [
            'view delonghi_network self_registered_users1',
            'view delonghi_network promotional_users',
            'view delonghi_network promotional_products',
            'view delonghi_network actual_sales',
            'view delonghi_network sales',
        ]
    }
}, ɵ1 = {
    permissions: {
        only: [
            'view delonghi_network self_registered_users1',
            'view delonghi_network promotional_users',
            'view delonghi_network promotional_products',
            'view delonghi_network actual_sales',
            'view delonghi_network sales',
        ]
    }
};
const routes = [
    {
        path: ':tabId',
        component: MainLayoutComponent,
        canActivate: [NgxPermissionsGuard],
        data: ɵ0
    },
    {
        path: '',
        component: MainLayoutComponent,
        canActivate: [NgxPermissionsGuard],
        data: ɵ1
    }
];
export class DelonghiNetworkRoutingModule {
}
export { ɵ0, ɵ1 };
