import {Component, OnInit} from '@angular/core';
import {GlobalStateService} from './global-state.service';
import {NgxPermissionsService} from 'ngx-permissions';
import {GoogleAnalyticsService} from './core/services/google-analytics.service';
import {Meta} from '@angular/platform-browser';
import {YandexMetrikaService} from './core/services/yandex-metrika.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    constructor(private state: GlobalStateService,
                private permissionService: NgxPermissionsService,
                private googleAnalyticsService: GoogleAnalyticsService,
                private yandexMetrikaService: YandexMetrikaService,
                private meta: Meta) {
        this.googleAnalyticsService.appendGaTrackingCode();
        try {
            this.yandexMetrikaService.startEventListener();
        } catch (ignored) {
        }
    }

    ngOnInit(): void {
        this.permissionService.loadPermissions(this.state.getPermissions());
        // See https://stackoverflow.com/a/4472910/4082772
        this.meta.addTag({name: 'viewport', content: 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no'});
    }
}
