import {Injectable} from '@angular/core';
import 'rxjs/Observable';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import {BehaviorSubject} from 'rxjs';
import {JwtHelperService} from '@auth0/angular-jwt';
import {CoreDataSource} from '../core.datasource';

@Injectable()
export class AuthService {
    public isUserLoggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(private dataSource: CoreDataSource) {
        this.isUserLoggedIn.next(this.authenticated());
    }

    authenticate(username: string, password: string) {
        return this.dataSource.authenticate(username, password);
    }

    saveToken(token: string) {
        this.dataSource.saveJwtToken(token);
    }

    authenticated() {
        return !!this.dataSource.getJwtToken();
    }

    logout() {
        this.isUserLoggedIn.next(false);
        this.dataSource.removeJwtToken();
        this.dataSource.logout().subscribe();
    }

    getDecodeJwtToken() {
        const helper = new JwtHelperService();
        return helper.decodeToken(this.dataSource.getJwtToken());
    }
}
