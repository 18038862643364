import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { GlobalStateService } from './global-state.service';
import { environment } from '../environments/environment';
import { VERSION } from '../environments/version';
const ɵ0 = environment.yaMetrikaId;
export class AppModule {
}
export function HttpLoaderFactory(http) {
    return new TranslateHttpLoader(http, '/assets/i18n/', '.json?version='
        + VERSION.version);
}
export function appFactory(provider) {
    return () => provider.init();
}
export { ɵ0 };
