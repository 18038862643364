<div class="row col-12 head-row flex-row justify-content-between">
    <h5>{{ 'delonghiNetwork.promotionalProducts' | translate}}</h5>
    <app-file-uploader
        url = "{{apiBaseUrl}}/api/delonghi-network/promotional-products"
    ></app-file-uploader>
</div>
<div class="table-container">
    <kendo-grid
        appKendoGridDataProvider
        url="{{apiBaseUrl}}/api/delonghi-network/promotional-products"
        [pageSize]="20"
        [pageable]="true"
        [sort]="sort"
        [sortable]="true"
        [filter]="state.filter"
        filterable="menu"
        class="scrollbar"
    >
        <kendo-grid-column field="period" title="{{'delonghiNetwork.period' | translate }}">
            <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
                <kendo-grid-date-filter-menu
                    [column]="column"
                    [filter]="filter"
                    [filterService]="filterService"
                    placeholder="Example: 01.12.2018"
                    formatPlaceholder="formatPattern"
                >
                    <kendo-filter-gte-operator></kendo-filter-gte-operator>
                    <kendo-filter-lte-operator></kendo-filter-lte-operator>
                </kendo-grid-date-filter-menu>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem>
                {{dataItem.period |  date:'MM/yyyy'}}
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="sku" title="{{'delonghiNetwork.model' | translate }}">
            <ng-template  kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
                <kendo-grid-string-filter-menu [column]="column" [filter]="filter" [filterService]="filterService" [extra]=false>
                    <kendo-filter-contains-operator></kendo-filter-contains-operator>
                </kendo-grid-string-filter-menu>
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="countItemsToGetBonus" title="{{'delonghiNetwork.numberOfSales' | translate }}">
            <ng-template  kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
                <kendo-grid-numeric-filter-menu [column]="column" [filter]="filter" [filterService]="filterService" [extra]=false>
                    <kendo-filter-eq-operator></kendo-filter-eq-operator>
                    <kendo-filter-gt-operator></kendo-filter-gt-operator>
                    <kendo-filter-lt-operator></kendo-filter-lt-operator>
                </kendo-grid-numeric-filter-menu>
            </ng-template>
        </kendo-grid-column>


        <kendo-grid-column field="bonus" title="{{'delonghiNetwork.bonusRate' | translate }}">
            <ng-template  kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
                <kendo-grid-numeric-filter-menu [column]="column" [filter]="filter" [filterService]="filterService" [extra]=false>
                    <kendo-filter-eq-operator></kendo-filter-eq-operator>
                    <kendo-filter-gt-operator></kendo-filter-gt-operator>
                    <kendo-filter-lt-operator></kendo-filter-lt-operator>
                </kendo-grid-numeric-filter-menu>
            </ng-template>
        </kendo-grid-column>

        <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">

            <div class="app-page-wrapper">
                <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
                <kendo-pager-numeric-buttons [buttonCount]="10"></kendo-pager-numeric-buttons>
                <kendo-pager-next-buttons></kendo-pager-next-buttons>
                <kendo-pager-info></kendo-pager-info>
            </div>
        </ng-template>
    </kendo-grid>
</div>

