import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, ErrorHandler, NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AppRoutingModule} from './app-routing.module';
import {HeaderComponent} from './core/components/header/header.component';
import {DelonghiCoreModule} from './core/core.module';
import {LoginComponent} from './core/pages/login/login.component';
import {AuthGuard} from './core/guards/auth.guard';
import {AuthService} from './core/services/auth.service';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {CoreDataSource} from './core/core.datasource';
import {UserRepository} from './core/repositories/user.repository';
import {GlobalStateService} from './global-state.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {NgxPermissionsModule} from 'ngx-permissions';
import {GlobalErrorHandler} from './core/errorHandler';
import {GoogleAnalyticsService} from './core/services/google-analytics.service';
import {FooterComponent} from './core/components/footer.component';
import {NotFoundComponent} from './core/components/not-found.component';
import {DelonghiNetworkModule} from './modules/delonghi-network/delonghi-network.module';
import {KendoGridTranslateMessageService} from './core/services/kendo-grid-translate-message.service';
import {MessageService} from '@progress/kendo-angular-l10n';
import {LayoutModule} from '@progress/kendo-angular-layout';
import {YandexMetrikaService} from './core/services/yandex-metrika.service';
import {MetrikaModule} from 'ng-yandex-metrika';
import {environment} from '../environments/environment';
import {VERSION} from '../environments/version';


@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        FooterComponent,
        LoginComponent,
        NotFoundComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        DelonghiCoreModule,
        HttpClientModule,
        AppRoutingModule,
        DelonghiNetworkModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        NgxPermissionsModule.forRoot(),
        LayoutModule,
        MetrikaModule.forRoot(
            {
                id: environment.yaMetrikaId,
                webvisor: true
            }
        ),
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: appFactory,
            deps: [GlobalStateService, NgxSpinnerService],
            multi: true
        },
        AuthGuard,
        AuthService,
        CoreDataSource,
        HttpClient,
        UserRepository,
        GlobalStateService,
        GlobalErrorHandler,
        GoogleAnalyticsService,
        YandexMetrikaService,
        {provide: ErrorHandler, useClass: GlobalErrorHandler},
        {provide: MessageService, useClass: KendoGridTranslateMessageService},
    ],
    bootstrap: [AppComponent]
})

export class AppModule {
}

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, '/assets/i18n/', '.json?version='
        + VERSION.version);
}

export function appFactory(provider: GlobalStateService) {
    return () => provider.init();
}
