import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import 'rxjs/add/observable/from';

@Injectable()
export class CoreDataSource {
    baseUrl = environment.api;
    drupalUrl = environment.drupalUrl;

    constructor(private http: HttpClient) {
    }

    authenticate(login: string, password: string): Observable<any> {
        return this.http.post<any>(this.baseUrl + '/api/login',
            {'username': login, 'password': password}, {withCredentials: true});
    }

    logout() {
        return this.http.get(this.baseUrl + '/api/logout', {withCredentials: true});
    }

    authenticateByCookie(): Observable<any> {
        return this.http.get<any>(this.baseUrl + '/login_check_by_cookie', {withCredentials: true});
    }

    saveJwtToken(token: string) {
        localStorage.setItem('token', token);
    }

    getJwtToken(): string {
        return localStorage.getItem('token');
    }

    removeJwtToken() {
        localStorage.removeItem('token');
    }

    getUser(id: number): Observable<any> {
        return this.http.get<any>(this.baseUrl + '/api/users/' + id);
    }

    getCurrentUser(): Observable<any> {
        return this.http.get<any>(this.baseUrl + '/api/users/current');
    }

    getHeaderLinks(locale = 'en'): Observable<any> {
        return this.http.get(this.drupalUrl + '/api/menu', {params: {locale: locale}, withCredentials: true});
    }

    getCSRFToken(): Observable<any> {
        return this.http.get<any>(this.drupalUrl + '/restws/session/token',
            {
                withCredentials: true,
                // @ts-ignore
                responseType: 'text'
            });
    }
}
