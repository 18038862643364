import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../../services/auth.service';
import {GlobalStateService} from '../../../global-state.service';
import {CoreDataSource} from '../../core.datasource';
import {UserRepository} from '../../repositories/user.repository';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    returnUrl: string;
    public errorMsg = '';


    loginForm = new FormGroup({
        username: new FormControl(),
        password: new FormControl()
    });

    constructor(
        private authService: AuthService,
        private router: Router,
        private route: ActivatedRoute,
        private globalState: GlobalStateService,
        private coreDataSource: CoreDataSource,
        private userRepository: UserRepository,
        private spinner: NgxSpinnerService) {
    }

    ngOnInit() {
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
        this.spinner.hide();
        this.authService.isUserLoggedIn.subscribe(response => {
            if (response) {
                this.router.navigateByUrl(decodeURIComponent(this.returnUrl)).then();
            }
        });
    }

    login() {
        this.spinner.show();
        return this.globalState.createPromise(
            this.authService.authenticate(this.loginForm.value.username, this.loginForm.value.password))
            .catch(error => {
                if (error.status === 401) {
                    this.spinner.hide();
                    throw Error('Sorry, unrecognized username or password.');
                }
            })
            .then(response => {
                this.authService.saveToken(response.token);
                return Promise.all([
                    this.globalState.createPromise(this.userRepository.getCurrentUser()),
                    this.globalState.createPromise(this.coreDataSource.getHeaderLinks(this.globalState.language))
                ]).catch(() => {
                    throw Error('Error retrieving data from server.');
                });
            })
            .then(response => {
                this.globalState.setPermissions(response[0]);
                this.globalState.user = response[0];
                this.globalState.setLinks(response[1]);
                this.authService.isUserLoggedIn.next(true);
                this.spinner.hide();
            })
            .catch(error => {
                    this.spinner.hide();
                    this.errorMsg = error.message;
                }
            );
    }
}
