/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./footer.component";
var styles_FooterComponent = ["app-footer[_nghost-%COMP%] { position:absolute; bottom: 0; right: 15px}"];
var RenderType_FooterComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_FooterComponent, data: {} });
export { RenderType_FooterComponent as RenderType_FooterComponent };
function View_FooterComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["Build: ", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.version.hash; _ck(_v, 1, 0, currVal_0); }); }
export function View_FooterComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "footer", [], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" Version: ", " "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FooterComponent_1)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.dev; _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.version.version; _ck(_v, 1, 0, currVal_0); }); }
export function View_FooterComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-footer", [], null, null, null, View_FooterComponent_0, RenderType_FooterComponent)), i0.ɵdid(1, 49152, null, 0, i2.FooterComponent, [], null, null)], null, null); }
var FooterComponentNgFactory = i0.ɵccf("app-footer", i2.FooterComponent, View_FooterComponent_Host_0, {}, {}, []);
export { FooterComponentNgFactory as FooterComponentNgFactory };
