import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';

@Injectable()
export class SaleService {

    constructor(private http: HttpClient) {
    }

    find(criteria: Object = {}) {
        // TODO: fix any
        return this.http.get<any>(
            `${environment.api}/api/delonghi-network/sales`,
            {params: {criteria: JSON.stringify(criteria)}}
        );
    }

    loadAsCsv(criteria: Object = {}) {
        return this.http
            .get(
                `${environment.api}/api/delonghi-network/export/sales`,
                {responseType: 'blob', params: {criteria: JSON.stringify(criteria)}}
            );
    }
}

