import { Routes } from '@angular/router';
import { LoginComponent } from './core/pages/login/login.component';
import { AuthGuard } from './core/guards/auth.guard';
import { NotFoundComponent } from './core/components/not-found.component';
const ɵ0 = () => import("./modules/reporting-module/reporting.module.ngfactory").then(m => m.ReportingModuleNgFactory), ɵ1 = () => import("./modules/rsm-schedule/schedule.module.ngfactory").then(m => m.ScheduleModuleNgFactory), ɵ2 = () => import("./modules/delonghi-network/delonghi-network.module.ngfactory").then(m => m.DelonghiNetworkModuleNgFactory), ɵ3 = () => import("./modules/info-space/info-space.module.ngfactory").then(m => m.InfoSpaceModuleNgFactory), ɵ4 = () => import("./modules/dictionaries/dictionaries.module.ngfactory").then(m => m.DictionariesModuleNgFactory);
const routes = [
    { path: 'login', component: LoginComponent },
    {
        path: '',
        loadChildren: ɵ0,
        canActivate: [AuthGuard]
    },
    {
        path: 'rsm-schedule',
        loadChildren: ɵ1,
        canActivate: [AuthGuard]
    },
    // {path: '', component: HomeComponent, canActivate: [AuthGuard]},
    {
        path: 'delonghi-network',
        loadChildren: ɵ2
    },
    {
        path: 'infospace',
        loadChildren: ɵ3,
        canActivate: [AuthGuard]
    },
    {
        path: 'dictionaries',
        loadChildren: ɵ4,
        canActivate: [AuthGuard]
    },
    { path: 'not-found', component: NotFoundComponent, canActivate: [AuthGuard] },
    { path: '**', component: NotFoundComponent, canActivate: [AuthGuard] }
];
export class AppRoutingModule {
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4 };
