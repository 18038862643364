import {Component} from '@angular/core';
import {SortDescriptor, State} from '@progress/kendo-data-query';
import {environment} from '../../../../../environments/environment';

@Component({
    selector: 'app-promotional-products-table',
    templateUrl: './promotional-products-table.component.html',
    styleUrls: ['./promotional-products-table.component.scss']
})
export class PromotionalProductsTableComponent {

    public apiBaseUrl = environment.api;

    public state: State = {
        filter: {
            logic: 'and',
            filters: []
        }
    };

    public sort: SortDescriptor[] = [{
        field: 'period',
        dir: 'desc'
    }];

    constructor() {
    }
}
