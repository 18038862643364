var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ElementRef, TemplateRef } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { faInfoCircle, faUpload } from '@fortawesome/free-solid-svg-icons';
import { HttpClient } from '@angular/common/http';
import { DialogService } from '@progress/kendo-angular-dialog';
import { TranslateService } from '@ngx-translate/core';
export class FileUploaderComponent {
    constructor(http, spinner, dialogService, translate) {
        this.http = http;
        this.spinner = spinner;
        this.dialogService = dialogService;
        this.translate = translate;
        // TODO: implement as @Input
        this.faUpload = faUpload;
        this.faInfo = faInfoCircle;
        this._uploadingResult = null;
        this._loading = false;
        this._loadingError = false;
    }
    get uploadingResult() {
        return this._uploadingResult;
    }
    get loading() {
        return this._loading;
    }
    get loadingError() {
        return this._loadingError;
    }
    openUploadDialog() {
        this.fileForUpload.nativeElement.click();
    }
    startUploadActiveSalesData(files) {
        return __awaiter(this, void 0, void 0, function* () {
            this._loading = true;
            this._loadingError = false;
            yield this.spinner.show('fileLoaderSpinner');
            this.postFile(files.item(0))
                .subscribe((res) => __awaiter(this, void 0, void 0, function* () {
                this._uploadingResult = res;
                this._loading = false;
                yield this.spinner.hide('fileLoaderSpinner');
            }), (error) => __awaiter(this, void 0, void 0, function* () {
                this._uploadingResult = null;
                this._loadingError = true;
                this._loading = false;
                yield this.spinner.hide('fileLoaderSpinner');
            }));
        });
    }
    openResultDialog(template) {
        return __awaiter(this, void 0, void 0, function* () {
            const title = yield this.translate.get('fileUploader.fileUploadingResult').toPromise();
            this.dialogService.open({
                title: title,
                content: template,
                width: 500,
                actions: [
                    { text: 'Ok', primary: true }
                ]
            });
        });
    }
    calculateInfoIconColor() {
        return (this.uploadingResult && Number(this.uploadingResult.countErrors) > 0) || this._loadingError
            ? 'red'
            : 'green';
    }
    postFile(fileToUpload) {
        const formData = new FormData();
        formData.append('file', fileToUpload);
        return this.http
            .post(this.url, formData);
    }
}
