/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../node_modules/ngx-spinner/ngx-spinner.ngfactory";
import * as i3 from "ngx-spinner";
import * as i4 from "./core/components/header/header.component.ngfactory";
import * as i5 from "./core/components/header/header.component";
import * as i6 from "./core/services/auth.service";
import * as i7 from "@angular/router";
import * as i8 from "@ngx-translate/core";
import * as i9 from "./core/core.datasource";
import * as i10 from "./global-state.service";
import * as i11 from "@angular/platform-browser";
import * as i12 from "./app.component";
import * as i13 from "ngx-permissions";
import * as i14 from "./core/services/google-analytics.service";
import * as i15 from "./core/services/yandex-metrika.service";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ngx-spinner", [["bdColor", "rgba(51,51,51,0.8)"], ["color", "#fff"], ["size", "medium"], ["type", "ball-clip-rotate"]], null, null, null, i2.View_NgxSpinnerComponent_0, i2.RenderType_NgxSpinnerComponent)), i1.ɵdid(1, 770048, null, 0, i3.NgxSpinnerComponent, [i3.NgxSpinnerService, i1.ChangeDetectorRef], { bdColor: [0, "bdColor"], size: [1, "size"], color: [2, "color"], type: [3, "type"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-header", [], null, null, null, i4.View_HeaderComponent_0, i4.RenderType_HeaderComponent)), i1.ɵdid(3, 49152, null, 0, i5.HeaderComponent, [i6.AuthService, i7.Router, i8.TranslateService, i9.CoreDataSource, i10.GlobalStateService, i3.NgxSpinnerService, i11.DomSanitizer], null, null), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "container-fluid main"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(6, 212992, null, 0, i7.RouterOutlet, [i7.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { var currVal_0 = "rgba(51,51,51,0.8)"; var currVal_1 = "medium"; var currVal_2 = "#fff"; var currVal_3 = "ball-clip-rotate"; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); _ck(_v, 6, 0); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 114688, null, 0, i12.AppComponent, [i10.GlobalStateService, i13.NgxPermissionsService, i14.GoogleAnalyticsService, i15.YandexMetrikaService, i11.Meta], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i12.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
