import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {CoreDataSource} from '../core.datasource';
import {Observable} from 'rxjs';
import {GlobalStateService} from '../../global-state.service';

@Injectable()
export class AddHeaderInterceptor implements HttpInterceptor {
    constructor(private dataSource: CoreDataSource, private state: GlobalStateService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Clone the request to add the new header
        const clonedRequest = req.clone({
            headers: req.headers.set('Authorization', 'Bearer ' + this.dataSource.getJwtToken())
                .set('Accept-Language', this.state.language)
        });

        // Pass the cloned request instead of the original request to the next handle
        return next.handle(clonedRequest);
    }
}
