export const environment = {
    production: false,
    api: 'https://sales-t2-v2.delonghidev.ru',
    googleMapsKey: 'AIzaSyDqZdzhjRytdlGbj3RQlBBIHkKgIuerzAg',
    drupalUrl: 'https://sales-t2.delonghidev.ru',
    googleAnalyticsKey: 'UA-130632417-1',
    reportingServiceApi: 'https://reporting-service-t1.delonghidev.ru/api',
    yaMetrikaId: '56002552',
    delonghiDomain: 'delonghidev.ru',
};
