import {Metrika} from 'ng-yandex-metrika';
import {Injectable} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {Location} from '@angular/common';
import {GlobalStateService} from '../../global-state.service';
import {environment} from '../../../environments/environment';
import {filter} from 'rxjs/operators';
import {CommonOptions} from 'ng-yandex-metrika/lib/ng-yandex-metrika.service';

@Injectable()
export class YandexMetrikaService {

    private isInitialized = false;

    constructor(
        private metrika: Metrika,
        private router: Router,
        private location: Location,
        private state: GlobalStateService
    ) {
    }

    public startEventListener() {
        if (environment.yaMetrikaId === null) {
            throw new Error('Yandex.Metrica id is not specified.');
        }

        this.isInitialized = true;
        let prevPath = this.location.path();

        this.router.events
            .pipe(filter(event => (event instanceof NavigationEnd)))
            .subscribe(event => {
                let newPath = this.location.path();
                if (newPath === '') {
                    newPath = '/';
                }
                this.metrika.hit(newPath, {
                    referer: prevPath,
                });

                prevPath = newPath;

                this.sendParams();
                this.sendUserParams();
            });
    }

    private sendParams() {
        const params = {
            user_id: -1,
            user_login: 'anonymous'
        };
        if (this.state.user) {
            params.user_id = this.state.user.id;
            params.user_login = this.state.user.name;
        }
        this.metrika.params(params);
    }

    private sendUserParams() {
        const userParams = {
            user_id: -1,
            user_login: 'anonymous',
        };
        if (this.state.user) {
            userParams.user_id = this.state.user.id;
            userParams.user_login = this.state.user.name;
        }
        this.metrika.userParams(userParams);
    }

    public fireEvent(type: string, options?: CommonOptions, counterPosition?: number): Promise<any> {
        if (!this.isInitialized) {
            return Promise.all([null]);
        }

        if (this.state.user) {
            options.params.user = {
                user_id: this.state.user.id,
                user_login: this.state.user.name,
            };
        }

        return this.metrika.fireEvent(type, options, counterPosition);
    }
}
