import {StringHelperService} from './string-helper.service';

export class DateHelperService {
    static getPeriodString(period, locale) {
        const date = new Date(period);
        const month = date.toLocaleString(
            locale, {month: 'long'});
        return StringHelperService.ucFirst(month) + ' ' + date.getFullYear();
    }

    /**
     * Returns
     * -1 if date1 > date2
     * 1 if date 1 < date2
     * 0 if date1 === date2
     */
    static compareDates(date1: Date, date2: Date): number {
        const equal = date1.getFullYear() === date2.getFullYear()
            && date1.getMonth() === date2.getMonth()
            && date1.getDate() === date2.getDate();

        if (equal) {
            return 0;
        }

        let isFuture = date1.getFullYear() > date2.getFullYear();

        if (!isFuture) {
            isFuture = date1.getMonth() > date2.getMonth();
        }

        if (!isFuture
            && date1.getFullYear() === date2.getFullYear()
            && date1.getMonth() === date2.getMonth()) {
            isFuture = date1.getDate() > date2.getDate();
        }

        return isFuture ? -1 : 1;
    }
}
