import {MessageService} from '@progress/kendo-angular-l10n';
import {Injectable} from '@angular/core';

const messages = {
  'kendo.grid.groupPanelEmpty': '  Переместите сюда заголовок колонки, чтобы сгрупировать записи из этой колонки',
  'kendo.grid.noRecords': '  Нет доступных записей',
  'kendo.grid.pagerFirstPage': '  Вернуться на первую страницу',
  'kendo.grid.pagerPreviousPage': '  Перейти на предыдущую страницу',
  'kendo.grid.pagerNextPage': '  Перейдите на следующую страницу',
  'kendo.grid.pagerLastPage': '  К последней странице',
  'kendo.grid.pagerPage': '  Страница',
  'kendo.grid.pagerOf': '  из',
  'kendo.grid.pagerItems': '  элементов',
  'kendo.grid.pagerItemsPerPage': '  элементов на странице',
  'kendo.grid.filter': '  Filter',
  'kendo.grid.filterEqOperator': '  равно',
  'kendo.grid.filterNotEqOperator': '  не равно',
  'kendo.grid.filterIsNullOperator': '  нулевой',
  'kendo.grid.filterIsNotNullOperator': '  Не равно null',
  'kendo.grid.filterIsEmptyOperator': '  пусто',
  'kendo.grid.filterIsNotEmptyOperator': '  Не пусто',
  'kendo.grid.filterStartsWithOperator': '  начинающимися на',
  'kendo.grid.filterContainsOperator': '  содержащими',
  'kendo.grid.filterNotContainsOperator': '  не содержит',
  'kendo.grid.filterEndsWithOperator': '  оканчивается на',
  'kendo.grid.filterGteOperator': '  больше или равно',
  'kendo.grid.filterGtOperator': '  больше',
  'kendo.grid.filterLteOperator': '  меньше или равно',
  'kendo.grid.filterLtOperator': '  меньше',
  'kendo.grid.filterIsTrue': '  истина',
  'kendo.grid.filterIsFalse': '  ложь',
  'kendo.grid.filterBooleanAll': '  (Все)',
  'kendo.grid.filterAfterOrEqualOperator': '  больше или равно',
  'kendo.grid.filterAfterOperator': '  больше',
  'kendo.grid.filterBeforeOperator': '  меньше',
  'kendo.grid.filterBeforeOrEqualOperator': '  меньше или равно',
  'kendo.grid.filterFilterButton': '  фильтровать',
  'kendo.grid.filterClearButton': '  очистить',
  'kendo.grid.filterAndLogic': '  И',
  'kendo.grid.filterOrLogic': '  или',
  'kendo.grid.loading': '  Загрузка',
  'kendo.grid.sort': '  Sort',
  'kendo.grid.columnMenu': '  Меню колонок',
  'kendo.grid.columns': '  Колонки',
  'kendo.grid.lock': '  Заблокировать',
  'kendo.grid.unlock': '  Разблокировать',
  'kendo.grid.sortAscending': '  Сортировать по возрастанию',
  'kendo.grid.sortDescending': '  Сортировать по убыванию',
  'kendo.grid.columnsApply': '  Применить',
  'kendo.grid.columnsReset': '  Сбросить',
  'kendo.datepicker.toggle': 'Переключить календарь',
  'kendo.datepicker.today': 'Сегодня',
};

@Injectable({providedIn: 'root'})
export class KendoGridTranslateMessageService extends MessageService {
  get(key: string): string | undefined {
    return messages[key];
  }
}
