import {Injectable} from '@angular/core';
import 'rxjs-compat/add/operator/map';
import {CoreDataSource} from './core/core.datasource';
import {AuthService} from './core/services/auth.service';
import {UserRepository} from './core/repositories/user.repository';
import {BehaviorSubject, Observable} from 'rxjs';
import {NgxPermissionsService} from 'ngx-permissions';
import {TranslateService} from '@ngx-translate/core';


@Injectable()
export class GlobalStateService {
    public hideHeader: BehaviorSubject<boolean> = new BehaviorSubject(GlobalStateService.isHeaderHidden());
    private permissions = [];
    private links = {main: [], management: [], reminders: []};
    private CSRFToken: string;

    constructor(private coreDataSource: CoreDataSource,
                private authService: AuthService,
                private userRepository: UserRepository,
                private permissionService: NgxPermissionsService,
                private translate: TranslateService) {
    }

    private _user;
    private _startPageConfiguration;

    get user() {
        return this._user;
    }

    set user(value) {
        this._user = value;
    }

    private _language;

    get language() {
        if (this._language === undefined) {
            let lang = localStorage.getItem('locale');
            if (lang === null) {
                lang = this.translate.getBrowserLang();
            }
            this.language = lang.match(/en|ru/) ? lang : 'en';
        }
        return this._language;
    }

    set language(value) {
        localStorage.setItem('locale', value);
        this._language = value;
    }

    private static isHeaderHidden() {
        return window.location.pathname.indexOf('rsm-schedule/mobile') !== -1;
    }

    init() {
        this.translate.addLangs(['en', 'ru']);
        this.translate.use(this.language);
        return this.createPromise(this.coreDataSource.authenticateByCookie())
            .then(response => {
                this.authService.saveToken(response.token);
                return Promise.all([
                    this.createPromise(this.userRepository.getCurrentUser()),
                    this.createPromise(this.coreDataSource.getHeaderLinks(this.language))
                ]);
            })
            .then(response => {
                this.setPermissions(response[0]);
                this.user = response[0];
                this._startPageConfiguration = response[0]['start-page'] === undefined ? null : response[0]['start-page'];
                this.setLinks(response[1]);
                this.authService.isUserLoggedIn.next(true);
            })
            .catch(() => {
                this.authService.logout();
            });
    }

    createPromise(observable: Observable<any>): Promise<any> {
        return new Promise((resolve, reject) => {
            observable.subscribe(data => resolve(data), error => reject(error));
        });
    }

    getPermissions() {
        return this.permissions;
    }

    setPermissions(user) {
        this.permissions = user.permissions.map(item => item.name);
        this.permissionService.loadPermissions(this.getPermissions());
    }

    setLinks(l) {
        this.links = l.data;
    }

    getLinks() {
        return this.links;
    }

    setCSRFToken(token: string): void {
        this.CSRFToken = token;
    }

    getCSRFToken() {
        return this.CSRFToken;
    }
}
