import {NgModule} from '@angular/core';
import {UiModule} from './ui.module';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';
import {NgxSpinnerModule} from 'ngx-spinner';
import {ToastrModule} from 'ngx-toastr';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {AddHeaderInterceptor} from './services/add-header.service';
import {HeaderService} from './services/header.service';
import {StringHelperService} from './helpers/string-helper.service';
import {DateHelperService} from './helpers/date-helper.service';
import {UrlHelperService} from './helpers/url-helper.service';
import {CsrfInterceptor} from './services/csrf-interceptor.service';

@NgModule({
    imports: [
        UiModule,
        CommonModule,
        ReactiveFormsModule,
        NgxSpinnerModule,
        ToastrModule.forRoot({
            onActivateTick: true
        }),
    ],
    declarations: [
    ],
    exports: [
        UiModule,
        CommonModule,
        ReactiveFormsModule,
        NgxSpinnerModule,
        ToastrModule,
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AddHeaderInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: CsrfInterceptor,
            multi: true,
        },
        HeaderService,
        StringHelperService,
        DateHelperService,
        UrlHelperService
    ]
})
export class DelonghiCoreModule {
}
