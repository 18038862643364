<div class="row col-12 head-row">
    <h5>{{ 'delonghiNetwork.sales' | translate}}</h5>
    <fa-icon (click)="loadAsCsv()" [icon]="faFileCsv" [size]="'2x'"></fa-icon>
</div>
<div class="table-container">
    <kendo-grid
        appDelonghiNetworkSalesBinding
        [pageSize]="20"
        [pageable]="true"
        [sort]="sort"
        [sortable]="true"
        [filter]="state.filter"
        filterable="menu"
        class="scrollbar"
    >
        <kendo-grid-column field="product.sku" title="{{'delonghiNetwork.productSku' | translate }}">
            <ng-template  kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
                <kendo-grid-string-filter-menu [column]="column" [filter]="filter" [filterService]="filterService" [extra]=false>
                    <kendo-filter-contains-operator></kendo-filter-contains-operator>
                </kendo-grid-string-filter-menu>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column-group title="{{ 'delonghiNetwork.sellerMessengerInfo'| translate }}" align="center">
            <kendo-grid-column field="seller.messengerAccount.id" title="{{ 'delonghiNetwork.accountId'| translate }}">
                <ng-template  kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
                    <kendo-grid-string-filter-menu [column]="column" [filter]="filter" [filterService]="filterService" [extra]=false>
                        <kendo-filter-contains-operator></kendo-filter-contains-operator>
                    </kendo-grid-string-filter-menu>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="seller.messengerAccount.name" title="{{ 'delonghiNetwork.accountName' | translate }}">
                <ng-template  kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
                    <kendo-grid-string-filter-menu [column]="column" [filter]="filter" [filterService]="filterService" [extra]=false>
                        <kendo-filter-contains-operator></kendo-filter-contains-operator>
                    </kendo-grid-string-filter-menu>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="seller.messengerAccount.type" title="{{ 'delonghiNetwork.messengerType' | translate }}">
                <ng-template  kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
                    <kendo-grid-string-filter-menu [column]="column" [filter]="filter" [filterService]="filterService" [extra]=false>
                        <kendo-filter-contains-operator></kendo-filter-contains-operator>
                    </kendo-grid-string-filter-menu>
                </ng-template>
            </kendo-grid-column>
        </kendo-grid-column-group>
        <kendo-grid-column-group title="{{ 'delonghiNetwork.sellerInfo'| translate }}" align="center">
            <kendo-grid-column field="seller.name" title="{{ 'delonghiNetwork.name' | translate }}">
                <ng-template  kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
                    <kendo-grid-string-filter-menu [column]="column" [filter]="filter" [filterService]="filterService" [extra]=false>
                        <kendo-filter-contains-operator></kendo-filter-contains-operator>
                    </kendo-grid-string-filter-menu>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="seller.phone" title="{{ 'delonghiNetwork.phone' | translate }}">
                <ng-template  kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
                    <kendo-grid-string-filter-menu [column]="column" [filter]="filter" [filterService]="filterService" [extra]=false>
                        <kendo-filter-contains-operator></kendo-filter-contains-operator>
                    </kendo-grid-string-filter-menu>
                </ng-template>
            </kendo-grid-column>
        </kendo-grid-column-group>
        <kendo-grid-column field="receivingDateTime" title="{{ 'delonghiNetwork.saleDateAndTime' | translate }}" [filter]="'date'" format="{0:dd.MM.yyyy}">
            <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
                <kendo-grid-date-filter-menu
                    [column]="column"
                    [filter]="filter"
                    [filterService]="filterService"
                    placeholder="Example: 01.12.2018"
                    formatPlaceholder="formatPattern"
                >
                    <kendo-filter-gte-operator></kendo-filter-gte-operator>
                    <kendo-filter-lte-operator></kendo-filter-lte-operator>
                </kendo-grid-date-filter-menu>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem>
                {{dataItem.receivingDateTime  |  date:'dd/MM/yyyy HH:mm:ss'}}
            </ng-template>
        </kendo-grid-column>
        <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">

            <div class="app-page-wrapper">
                <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
                <kendo-pager-numeric-buttons [buttonCount]="10"></kendo-pager-numeric-buttons>
                <kendo-pager-next-buttons></kendo-pager-next-buttons>
                <kendo-pager-info></kendo-pager-info>
            </div>
        </ng-template>
    </kendo-grid>
</div>

