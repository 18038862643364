import {Injectable} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {GoogleAnalyticsService} from './google-analytics.service';
import * as ErrorStackParser from 'error-stack-parser';
import {StackFrame} from 'error-stack-parser';

@Injectable()
export class ErrorLogService {
    static logError({message, url, error}) {
        let text = 'Url: ' + url + '\n';
        if (error instanceof HttpErrorResponse) {
            text += ' There was an HTTP error. ' + message;
        } else if (error instanceof TypeError) {
            text += ' There was a Type error. ' + message + '\n';
            ErrorStackParser.parse(error).forEach(function (row: StackFrame) {
                text += 'FileName: ' + row.fileName + ' functionName: ' + row.functionName + ' columnNumber: ' + row.columnNumber + '\n';
            });
        } else if (error instanceof Error) {
            text += 'Type: General error. Message: ' + message + '\n';
            ErrorStackParser.parse(error).forEach(function (row: StackFrame) {
                text += 'FileName: ' + row.fileName + ' functionName: ' + row.functionName + ' columnNumber: ' + row.columnNumber + '\n';
            });
        } else {
            text += ' Nobody threw an Error but something happened! ' + message;
        }

        if (environment.production) {
            GoogleAnalyticsService.emitEventException(text);
        } else {
            console.error(text);
        }
    }
}
