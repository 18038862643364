import {NgModule} from '@angular/core';
import {ScheduleRoutingModule} from './schedule-routing.module';
import {ScheduleViewComponent} from './pages/schedule-view/schedule-view.component';
import {DelonghiCoreModule} from '../../core/core.module';
import {ScheduleDataSource} from './model/schedule.datasource';
import {ScheduleRepository} from './model/schedule.repository';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {ScheduleEditComponent} from './pages/schedule-edit/schedule-edit.component';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {PointsComponent} from './components/schedule-edit-points/points.component';
import {SkyhookDndModule, SkyhookDndService} from '@angular-skyhook/core';
import {customMultiBackend} from './customMultiBackend';
import {PointComponent} from './components/schedule-edit-point/point.component';
import {CheckpointComponent} from './components/schedule-edit-checkpoint/checkpoint.component';
import {PointsFilterPipe} from './components/schedule-edit-points/points-filter.pipe';
import {FormsModule} from '@angular/forms';
import {AgmCoreModule} from '@agm/core';
import {GeocodeService} from '../../core/services/geocode.service';
import {ScheduleDayViewComponent} from './components/schedule-view-day/schedule-day-view.component';
import {ScheduleEditTypeDayComponent} from './components/schedule-edit-type-day/schedule-edit-type-day.component';
import {ScheduleEditDayComponent} from './components/schedule-edit-day/schedule-edit-day.component';
import {ScheduleEditService} from './services/schedule-edit.service';
import {ModalConfirmComponent} from './components/schedule-edit-type-day/modal-confirm.component';
import {RoutePointFormComponent} from './pages/route-point-edit/route-point-form.component';
import {environment} from '../../../environments/environment';
import {NgxPermissionsModule} from 'ngx-permissions';
import {MultiCheckFilterComponent} from './pages/schedule-view/multicheck-filter.component';
import {SharedModule} from './shared.module';
import {KendoModule} from '../../core/kendo.module';
import {ActivityTypeNamePipe} from './pages/schedule-edit/activity-type-name.pipe';
import {OrderByPipe} from './order-by.pipe';
import {ScheduleEditCheckpointListComponent} from './components/schedule-edit-checkpoint-list/schedule-edit-checkpoint-list.component';
import {RouteSpecificationViewComponent} from './components/route-specification-view/route-specification-view.component';


@NgModule({
    imports: [
        DelonghiCoreModule,
        FormsModule,
        ScheduleRoutingModule,
        HttpClientModule,
        KendoModule,
        SkyhookDndModule.forRoot({backendFactory: customMultiBackend}),
        AgmCoreModule.forRoot({
            apiKey: environment.googleMapsKey
        }),
        TranslateModule.forChild({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        NgxPermissionsModule.forChild(),
        SharedModule
    ],
    providers: [
        ScheduleDataSource,
        ScheduleRepository,
        SkyhookDndService,
        GeocodeService,
        ScheduleEditService
    ],
    declarations: [
        ScheduleViewComponent,
        ScheduleEditComponent,
        PointsComponent,
        PointComponent,
        CheckpointComponent,
        PointsFilterPipe,
        RoutePointFormComponent,
        ScheduleDayViewComponent,
        ScheduleEditTypeDayComponent,
        ScheduleEditDayComponent,
        ModalConfirmComponent,
        MultiCheckFilterComponent,
        ActivityTypeNamePipe,
        OrderByPipe,
        ScheduleEditCheckpointListComponent,
        RouteSpecificationViewComponent
    ],
    entryComponents: [
        ModalConfirmComponent
    ]
})
export class ScheduleModule {
}

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}
