import {Component, ElementRef, ViewChild} from '@angular/core';
import {SortDescriptor, State} from '@progress/kendo-data-query';
import {faUpload} from '@fortawesome/free-solid-svg-icons';
import {environment} from '../../../../../environments/environment';
import {SalesTableDirective} from '../sales-table/sales-table.directive';

@Component({
    selector: 'app-actual-sales-table',
    templateUrl: './actual-sales-table.component.html',
    styleUrls: ['./actual-sales-table.component.scss']
})
export class ActualSalesTableComponent {

    public apiBaseUrl = environment.api;

    public state: State = {
        filter: {
            logic: 'and',
            filters: []
        }
    };

    public sort: SortDescriptor[] = [{
        field: 'period',
        dir: 'desc'
    }];

    public faUpload = faUpload;
    @ViewChild(SalesTableDirective, { static: false }) dataProvider: SalesTableDirective;
    @ViewChild('activeSalesFile', { static: false }) fileForUpload: ElementRef;

    constructor() {
    }

    public openUploadDialog() {
        this.fileForUpload.nativeElement.click();
    }
}
