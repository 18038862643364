import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DelonghiCoreModule} from '../../core/core.module';

import {DelonghiNetworkRoutingModule} from './delonghi-network-routing.module';
import {SalesTableComponent} from './components/sales-table/sales-table.component';
import {SalesTableDirective} from './components/sales-table/sales-table.directive';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {HttpClient} from '@angular/common/http';
import {HttpLoaderFactory} from '../rsm-schedule/schedule.module';
import {SaleService} from './service/sale.service';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {KendoGridDataProviderDirective} from './directive/kendo-grid-data-provider.directive';
import {PromotionalUsersTableComponent} from './components/promotional-users-table/promotional-users-table.component';
import {FileUploaderComponent} from '../../core/components/file-uploader/file-uploader.component';
import {UiModule} from '../../core/ui.module';
import {GridModule} from '@progress/kendo-angular-grid';
import {DialogsModule} from '@progress/kendo-angular-dialog';
import {PromotionalProductsTableComponent} from './components/promotional-products-table/promotional-products-table.component';
import {ActualSalesTableComponent} from './components/actual-sales-table/actual-sales-table.component';
import {SellersTableComponent} from './components/sellers-table/sellers-table.component';
import {TabContentLoadOnDemandDirective} from './directive/lazyload-tab.directive';
import {MainLayoutComponent} from './components/main-layout/main-layout.component';
import { TabStripModule } from '@progress/kendo-angular-layout';
import {NgxPermissionsModule} from 'ngx-permissions';

@NgModule({
    declarations: [
        FileUploaderComponent,
        SalesTableComponent,
        SalesTableDirective,
        PromotionalUsersTableComponent,
        PromotionalProductsTableComponent,
        ActualSalesTableComponent,
        SellersTableComponent,
        KendoGridDataProviderDirective,
        TabContentLoadOnDemandDirective,
        MainLayoutComponent
    ],
    imports: [
        DelonghiCoreModule,
        UiModule,
        FontAwesomeModule,
        CommonModule,
        TabStripModule,
        DelonghiNetworkRoutingModule,
        NgxPermissionsModule,
        TranslateModule.forChild({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        GridModule,
        DialogsModule,
    ],
    exports: [
        KendoGridDataProviderDirective
    ],
    providers: [
        SaleService,
    ]
})
export class DelonghiNetworkModule {
}

