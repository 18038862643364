import {ErrorHandler, Injectable, Injector, NgZone} from '@angular/core';
import {ErrorLogService} from './services/error-log.service';
import {AuthService} from './services/auth.service';
import {Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {LocationStrategy, PathLocationStrategy} from '@angular/common';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

    constructor(private authService: AuthService,
                private injector: Injector,
                private ngZone: NgZone,
                private spinner: NgxSpinnerService) {
    }

    public navigate(commands: any[]): void {
        const router = this.injector.get(Router);
        this.ngZone.run(() => router.navigate(commands,
            {queryParams: {returnUrl: router.url}})).then();
    }

    handleError(error) {
        const location = this.injector.get(LocationStrategy);
        const message = error.message ? error.message : error.toString();
        const url = location instanceof PathLocationStrategy
            ? location.path() : '';

        ErrorLogService.logError({message, url, error});

        if (error.status === 401) {
            this.spinner.hide();
            this.authService.logout();
            this.navigate(['/login']);
        }
    }
}
