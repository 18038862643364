<ngx-spinner
        bdColor="rgba(51,51,51,0.8)"
        color="#fff"
        size="medium"
        type="ball-clip-rotate">
</ngx-spinner>
<app-header></app-header>
<div class="container-fluid main">
    <router-outlet></router-outlet>
</div>
